import React, { useEffect, useState } from 'react'
import { MessageHeader, MessageBody, MessageFooter } from '../Main'
import { useDispatch, useSelector } from 'react-redux'
import { InteractorChatMessageUpdate } from '../../interactors/Main'
import { FetchListRooms } from '../../redux/room/actionCreator'

const ChatMessage = (data) => {
  const dispatch = useDispatch()
  const { listRooms } = useSelector((state) => state.room)
  const [dataRoom, setDataRoom] = useState({})
  const [newChat, setNewChat] = useState({})
  const { account } = useSelector((state) => state.account)
  const { messageUpdate } = InteractorChatMessageUpdate()

  useEffect(() => {
    if (data.payloadConversation?.room_code) {
      setDataRoom(data.payloadConversation)
      if (data.payloadConversation.total_unread > 0) {
        messageUpdate({
          room_code: data.payloadConversation.room_code,
          chat_to_reference_uid: account?.role === 'CS' ? 0 : account.reference_uid,
          updated_by: account.full_name,
        })
        let arrRooms = []
        listRooms.forEach((obj) => {
          obj.additional = data.payloadConversation.additional ?? ''
          if (obj.room_code === data.payloadConversation?.room_code) {
            obj.total_unread = 0
          }
          arrRooms.push(obj)
        })
        dispatch(FetchListRooms(arrRooms))
      }
    }
  }, [
    account.full_name,
    account.reference_uid,
    account?.role,
    data.payloadConversation,
    dispatch,
    listRooms,
    messageUpdate,
  ])

  const handleOnChatSend = (row) => setNewChat(row)

  const handleResetNewChat = () => setNewChat({})

  return (
    <>
      <main className="main main-visible">
        <div className="chats">
          <div className="chat-body">
            <MessageHeader dataRoom={dataRoom} />
            <MessageBody
              dataRoom={dataRoom}
              onChatSend={newChat}
              resetChandSend={handleResetNewChat}
            />
            <MessageFooter dataRoom={dataRoom} onChatSend={handleOnChatSend} />
          </div>
        </div>
      </main>
    </>
  )
}

export default ChatMessage
