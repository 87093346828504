import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { currencyFormat } from '../../utility/Helpers'
import { useTranslation } from 'react-i18next'

const CardTransaction = (data) => {
  const { t } = useTranslation()
  const [dataCard, setDataCard] = useState(null)
  useEffect(() => {
    if (data.dataRoom?.additional !== '') {
      setDataCard(data.dataRoom?.additional.transaction)
    }
  }, [data.dataRoom?.additional])

  const handleRemoveAdditional = () => {
    setDataCard(null)
    data.onRemoveAdditional('TRANSACTION')
  }

  return (
    <>
      {dataCard && (
        <div className="card-item">
          <div className="card-item-content">
            <div className="container">
              <div className="row pt-3 pb-3">
                <div className="col-12 d-inline-block">
                  <div className="col-12 d-inline-block">
                    <div className="d-flex justify-content-between">
                      <div className="row col-1">
                        <i className="fa fa-shopping-cart fa-2x" />
                      </div>
                      <div className="col-10 font-weight-bold">
                        <div className="row col-12 p-0 m-0">
                          <small>{t(`initial_status.${dataCard.status}`)}</small>
                          {t(`title.order_number`) + ': ' + dataCard.transaction_code}
                          <br />
                          {currencyFormat(
                            dataCard.payment_amount,
                            dataCard.payment_amount_currency,
                          )}
                        </div>
                      </div>
                      <div className="row col-1">
                        <Link to={'#'} onClick={() => handleRemoveAdditional()}>
                          <i className="fa fa-times" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CardTransaction
