import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'
import { setCookies } from '../../../utility/Cookies'
import { useDispatch } from 'react-redux'
import { encryptCrypto } from '../../../utility/Encryption'
import { FetchAccount } from '../../../redux/account/actionCreator'

const InteractorAccountToken = () => {
  const dispatch = useDispatch()
  const syncToken = useCallback(
    (payload, push_token) => {
      const account = { ...payload }
      account.notification_token = push_token
      const bodyPayload = {
        uid: account.reference_uid,
        notification_token: push_token,
      }
      if (account.role !== 'CS') {
        if (account.role === 'SELLER') {
          bodyPayload.source = 'SELLER'
        }
        ApiService.jsonRequest('/account-user/sync-push-token', bodyPayload, () => {
          setCookies({
            key: '_wchat',
            value: encryptCrypto(JSON.stringify(account)),
            expires: new Date().getTime() + 8 * 3600 * 1000, // 8 Hours
          })
          dispatch(FetchAccount(account))
        })
      } else {
        ApiService.jsonRequest('/team-account/sync-push-token', bodyPayload, () => {
          setCookies({
            key: '_wchat',
            value: encryptCrypto(JSON.stringify(account)),
            expires: new Date().getTime() + 8 * 3600 * 1000, // 8 Hours
          })
          dispatch(FetchAccount(account))
        })
      }
    },
    [dispatch],
  )
  return { syncToken }
}

export default InteractorAccountToken
