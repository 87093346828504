import React, { useEffect } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import AppLayout from './layouts/AppLayout'
import { InteractorAuthorization } from './interactors/Main'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from './components/Main'
import { ShowLoading } from './redux/loading/actionCreator'
import { getCookies } from './utility/Cookies'
import FirebaseNotification from './firebase/FirebaseNotification'

export default function App() {
  const { loading } = useSelector((state) => state.loading)
  const { account } = useSelector((state) => state.account)
  const dispatch = useDispatch()
  const { token } = InteractorAuthorization()
  useEffect(() => {
    if (!getCookies('_wide')) {
      dispatch(ShowLoading())
      token()
    }
    if (!account) {
      window.location.href = process.env.REACT_APP_DOMAIN_MARKETPLACE + '/account/signin'
    }
  }, [account, dispatch, token])

  return (
    <>
      {loading || !account ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <div className="min-vh-100 align-items-center d-flex flex-column justify-content-center">
                <Loading />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<AppLayout />} />
          </Routes>
          {account && <FirebaseNotification />}
        </BrowserRouter>
      )}
    </>
  )
}
