import { useCallback } from 'react'
import ApiService from '../../../../services/ApiService'

const InteractorChatMessageUpdate = () => {
  const messageUpdate = useCallback((payload) => {
    ApiService.jsonRequest('/communication-chat/message-update', payload, () => {})
  }, [])
  return { messageUpdate }
}

export default InteractorChatMessageUpdate
