import React, { useEffect, useState, useRef, useCallback } from 'react'
import { BubbleItem } from '../Main'
import { InteractorChatMessageSearch, InteractorChatMessageUpdate } from '../../interactors/Main'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { SetNewMessage } from '../../redux/room/actionCreator'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const MessageBody = ({ dataRoom, onChatSend, resetChandSend }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { account } = useSelector((state) => state.account)
  const { newMessage } = useSelector((state) => state.room)
  const bottomRef = useRef(null)
  const chatRef = useRef(null)
  const [height, setHeight] = useState(0)
  const [chatItem, setChatItem] = useState([])
  const [pages, setPages] = useState({})
  const { messageSearch } = InteractorChatMessageSearch()
  const { messageUpdate } = InteractorChatMessageUpdate()
  const { windowHeight } = useWindowDimensions()
  const [loadMore, setLoadMore] = useState(false)
  const [loading, setLoading] = useState(false)
  const [chatRoom, setChatRoom] = useState({})
  const [payload, setPayload] = useState({
    page: 1,
    limit: Math.floor(windowHeight / 100),
    keywords: {
      text_search: '',
      room_code: dataRoom.room_code,
    },
  })

  const handleReponseSearch = useCallback(
    (data) => {
      if (data?.items?.length > 0) {
        setChatItem(data.items)
        setPages(data.pages)
        if (payload.page === data.pages.total_pages || data.pages.total_pages === 0) {
          setLoadMore(false)
        } else {
          setLoadMore(true)
        }
      }
    },
    [payload.page],
  )

  const handleReponsePagination = useCallback(
    (data) => {
      if (data?.items?.length > 0) {
        let prevItems = chatItem.concat(data.items)
        setChatItem(prevItems)
        setPages(data.pages)
        if (payload.page === data.pages.total_pages || data.pages.total_pages === 0) {
          setLoadMore(false)
        } else {
          setLoadMore(true)
        }
      }
    },
    [chatItem, payload.page],
  )

  const handleNewChat = useCallback(
    (row) => {
      if (loading) {
        let prevItems = chatItem
        const obj = {
          additional: row.additional ?? '',
          attachment_media_type: row.attachment_media_type,
          attachment_url: row.attachment_url,
          chat_from_name: account.full_name,
          chat_from_picture: account.profile_picture,
          chat_from_role: account.role,
          chat_from_uid: account.reference_uid,
          created_by: account.full_name,
          created_date: row.created_date,
          reply_chat_message: row.reply_chat_message_uid,
          room_code: row.room_code,
          status: row.status,
          status_read: row.status_read,
          status_received: row.status_received,
          text_message: row.text_message,
          uid: row.uid,
        }
        prevItems.push(obj)
        setChatItem(prevItems)
        setLoading(false)
        resetChandSend()
      }
    },
    [
      account.full_name,
      account.profile_picture,
      account.reference_uid,
      account.role,
      chatItem,
      loading,
      resetChandSend,
    ],
  )

  // const fetchDataChat = useCallback(
  //   (obj) => {
  //     setChatItem([])
  //     messageSearch(obj, handleReponseSearch, setLoading)
  //     setPayload(obj)
  //   },
  //   [handleReponseSearch, messageSearch],
  // )

  const incomingChat = useCallback(
    (row) => {
      if (dataRoom?.room_code && row.room_code) {
        let prevItems = chatItem
        const obj = {
          additional: row.additional ?? '',
          attachment_media_type: row.attachment_media_type,
          attachment_url: row.attachment_url,
          chat_from_name: row.chat_from_name,
          chat_from_picture: row.chat_from_picture,
          chat_from_role: row.chat_from_role,
          chat_from_uid: row.chat_from_uid,
          created_date: row.created_date,
          reply_chat_message: row.reply_chat_message,
          room_code: row.room_code,
          status: row.status,
          status_read: row.status_read,
          status_received: row.status_received,
          text_message: row.text_message,
          uid: row.uid,
        }
        prevItems.push(obj)
        setChatItem(prevItems)
        dispatch(SetNewMessage(null))
        messageUpdate({
          room_code: row.room_code,
          chat_to_reference_uid: account.reference_uid,
          updated_by: account.full_name,
        })
      }
    },
    [
      account.full_name,
      account.reference_uid,
      chatItem,
      dataRoom?.room_code,
      dispatch,
      messageUpdate,
    ],
  )

  useEffect(() => {
    if (dataRoom?.room_code && Object.keys(chatRoom).length === 0) {
      setChatRoom(dataRoom)
      const objPayload = { ...payload }
      objPayload.page = 1
      objPayload.keywords.room_code = dataRoom.room_code
      messageSearch(objPayload, handleReponseSearch, setLoading)
      setPayload(objPayload)
    } else if (dataRoom?.room_code && chatRoom?.room_code) {
      if (chatRoom.room_code !== dataRoom.room_code) {
        setChatRoom(dataRoom)
        const objPayload = { ...payload }
        objPayload.page = 1
        objPayload.keywords.room_code = dataRoom.room_code
        messageSearch(objPayload, handleReponseSearch, setLoading)
        setPayload(objPayload)
      }
    }

    if (Object.keys(onChatSend).length > 0) {
      setLoading(true)
      handleNewChat(onChatSend)
    }
    if (newMessage) {
      incomingChat(newMessage)
    }
    setHeight(chatRef.current.clientHeight)
    bottomRef.current.scrollIntoView()
  }, [
    chatRoom,
    dataRoom,
    // fetchDataChat,
    handleNewChat,
    handleReponseSearch,
    incomingChat,
    messageSearch,
    newMessage,
    onChatSend,
    payload,
  ])

  const handlePagination = () => {
    const page_next = payload.page + 1
    if (page_next <= pages.total_pages) {
      let prevPayload = { ...payload }
      prevPayload.page = page_next
      messageSearch(prevPayload, handleReponsePagination, setLoading)
      setPayload(prevPayload)
    } else {
      setLoadMore(false)
    }
  }
  chatItem.sort((a, b) => new Date(b.created_date) - new Date(a.created_date))
  return (
    <div className="chat-content d-flex justify-content-center" ref={chatRef}>
      <div className="container p-0 m-0">
        <div
          id="body-scroll"
          className="p-2"
          style={{
            height: height,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
        >
          <InfiniteScroll
            dataLength={chatItem.length}
            next={handlePagination}
            inverse={true}
            hasMore={loadMore}
            loader={<div className="load-message sticky-top" data-label={t('title.loading')}></div>}
            scrollableTarget="body-scroll"
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
          >
            {chatItem.length > 0 &&
              chatItem.map((row, index) => {
                return <BubbleItem key={index} item={row} />
              })}
          </InfiniteScroll>
        </div>
        <div ref={bottomRef} />
      </div>
    </div>
  )
}

MessageBody.propTypes = {
  dataRoom: PropTypes.any,
  onChatSend: PropTypes.any,
  resetChandSend: PropTypes.any,
}

export default MessageBody
