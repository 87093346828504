import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CardProduct, CardQuotation, CardTransaction, CardAttachment } from '../Main'
import { useSelector } from 'react-redux'
import Picker from 'emoji-picker-react'
import { showAlert } from '../../utility/SweetAlert'
import { getExtension } from '../../utility/Helpers'
import { InteractorChatMessageCreate } from '../../interactors/Main'

const MessageFooter = (data) => {
  const { t } = useTranslation()
  const { createWithoutFile, createWithFile } = InteractorChatMessageCreate()
  const fileRef = useRef(null)
  const { account } = useSelector((state) => state.account)
  const [dataRoom, setDataRoom] = useState({})
  const [showEmoji, setShowEmoji] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState([])
  const [payloadActive, setPayloadActive] = useState(0)
  const [attachmentList, setAttachmentList] = useState([])
  const createPayload = useCallback(
    (row) => {
      const obj = {
        room_code: row.room_code ?? null,
        chat_from_picture: account.profile_picture ?? '',
        chat_from_name: account.full_name,
        chat_from_role: account.role,
        chat_from_reference_uid: account.reference_uid,
        chat_to_picture: row.chat_to_picture ?? '',
        chat_to_name: row.chat_to_name ?? '',
        chat_to_role: row.chat_to_role ?? null,
        chat_to_reference_uid: row.chat_to_reference_uid ?? null,
        text_message: '',
        attachment_media_type: row.attachment_media_type ?? null,
        attachment_url: row.attachment_url ?? null,
        reply_chat_message_uid: row.reply_chat_message_uid ?? null,
        product_uid: row.product_uid ?? null,
        request_quotation_uid: row.request_quotation_uid ?? null,
        transaction_uid: row.transaction_uid ?? null,
        status_received: 'Y',
        status_read: 'N',
        created_by: account.full_name,
      }
      return obj
    },
    [account.full_name, account.profile_picture, account.reference_uid, account.role],
  )

  useEffect(() => {
    setDataRoom(data.dataRoom)
    if (data.dataRoom?.room_code) {
      setPayload([
        createPayload({
          room_code: data.dataRoom?.room_code,
          chat_to_role: data.dataRoom?.chat_to_role,
          chat_to_picture: data.dataRoom?.chat_to_picture,
          chat_to_name: data.dataRoom?.chat_to_name,
          chat_to_reference_uid: data.dataRoom?.chat_to_uid,
          product_uid: data.dataRoom.additional?.product?.uid ?? null,
          request_quotation_uid: data.dataRoom.additional?.quotation?.uid ?? null,
          transaction_uid: data.dataRoom.additional?.transaction?.uid ?? null,
        }),
      ])
    }
  }, [createPayload, data.dataRoom])

  const handleRemoveAdditional = (val) => {
    const arrPayload = [...payload]
    if (val === 'PRODUCT') {
      arrPayload[payloadActive].product_uid = null
      dataRoom.additional.product = null
    }
    if (val === 'QUOTATION') {
      arrPayload[payloadActive].request_quotation_uid = null
      dataRoom.additional.transaction = null
    }
    if (val === 'TRANSACTION') {
      arrPayload[payloadActive].transaction_uid = null
      dataRoom.additional.transaction = null
    }
    setPayload(arrPayload)
  }

  const handleMessageInput = (e) => {
    if (showEmoji) setShowEmoji(false)

    const arrPayload = [...payload]
    let msg = e.target.value
    if (e.target.value.length > 2000) {
      showAlert({
        icon: 'warning',
        message: t('message.maximum_length_2000'),
        timer: 3000,
      })
      msg = msg.substring(0, 2000)
    }
    arrPayload[payloadActive].text_message = msg
    setPayload(arrPayload)
  }

  const onEmojiShow = () => setShowEmoji(!showEmoji)

  const onEmojiClick = (e, emojiObject) => {
    if (e.target) {
      const arrPayload = [...payload]
      arrPayload[payloadActive].text_message =
        payload[payloadActive].text_message + emojiObject.emoji
      setPayload(arrPayload)
    }
  }

  const handleUploadFile = (event) => {
    const file = event.target.files
    if (file.length === 0) {
      showAlert({
        icon: 'warning',
        message: t('message.please_select_file'),
        timer: 3000,
      })
    }
    const attachment_current = attachmentList.length
    const attachment_upload = file.length
    const total_attachment = attachment_current + attachment_upload

    if (total_attachment > 5) {
      showAlert({
        icon: 'warning',
        message: t('message.maximum_upload_5'),
        timer: 3000,
      })
    } else {
      let error_maximum_size = false
      let temp_payload = []
      let temp_attachment = []
      for (let index = 0; index < total_attachment; index++) {
        let file_name = attachmentList[index]?.file?.name ?? null
        let file_size = attachmentList[index]?.file?.size ?? null
        let file_data = attachmentList[index]?.file ?? null
        let file_type = attachmentList[index]?.file?.type ?? null

        if (!file_name && !file_size) {
          let idx = index
          if (index + 1 > attachment_current) {
            idx = index - attachment_current
          }

          file_name = file[idx].name
          file_size = file[idx].size
          file_data = file[idx]
          file_type = file[idx].type
        }
        const extension = getExtension(file_name).toUpperCase()
        const fileSize = file_size / 1024 / 1024
        if (fileSize > 10) {
          error_maximum_size = true
        }

        // IDENTIFICATION PAYLOAD
        if (payload[index]) {
          const obj = { ...payload[index] }
          obj.attachment_url = file_name
          obj.attachment_media_type = extension
          temp_payload.push(obj)
        } else {
          // CREATE NEW PAYLOAD
          const obj = createPayload({
            room_code: dataRoom.room_code,
            chat_to_role: dataRoom.chat_to_role,
            chat_to_name: dataRoom.chat_to_name,
            chat_to_picture: dataRoom.chat_to_picture,
            chat_to_reference_uid: dataRoom.chat_to_uid,
            attachment_url: file_name,
            attachment_media_type: extension,
          })
          temp_payload.push(obj)
        }
        // FOR DATA ATTACHMENT
        temp_attachment.push({
          index: index,
          file_type: file_type,
          extension: extension,
          file: file_data,
        })
      }

      // SET
      if (error_maximum_size) {
        showAlert({
          icon: 'warning',
          message: t('message.maximum_size'),
          timer: 3000,
        })
      } else {
        setAttachmentList(temp_attachment)
        setPayload(temp_payload)
      }
    }
  }

  const handleMessageInputActive = (index) => {
    if (attachmentList[index]) {
      setPayloadActive(index)
    } else {
      setPayloadActive(0)
    }
  }

  const handleRemoveAttachment = (index) => {
    // ATTACHMENT
    const prevAttachment = attachmentList
    delete prevAttachment[index]
    let temp_attachment = []
    prevAttachment.forEach((row, idx) => {
      temp_attachment.push({
        index: idx,
        file_type: row.file_type,
        extension: row.extension,
        file: row.file,
      })
    })
    // PAYLOAD
    const prevPayload = payload
    delete prevPayload[index]
    let temp_payload = []
    prevPayload.forEach((row) => {
      temp_payload.push(row)
    })
    // SET NEW
    setPayload(temp_payload)
    setAttachmentList(temp_attachment)
    setPayloadActive(0)
    if (temp_payload.length === 0) {
      setPayload([
        createPayload({
          room_code: data.dataRoom?.room_code,
          chat_to_role: data.dataRoom?.chat_to_role,
          chat_to_name: data.dataRoom?.chat_to_name,
          chat_to_picture: data.dataRoom?.chat_to_picture,
          chat_to_reference_uid: data.dataRoom?.chat_to_uid,
        }),
      ])
    }
  }

  const handleCancelAttachment = () => {
    setPayloadActive(0)
    setAttachmentList([])
    setPayload([
      createPayload({
        room_code: data.dataRoom?.room_code,
        chat_to_role: data.dataRoom?.chat_to_role,
        chat_to_picture: data.dataRoom?.chat_to_picture,
        chat_to_name: data.dataRoom?.chat_to_name,
        chat_to_reference_uid: data.dataRoom?.chat_to_uid,
      }),
    ])
  }

  const handleResponseCreateMessage = (row) => data.onChatSend(row)

  const handleSendMessage = () => {
    setShowEmoji(false)
    if (payload.length > 0) {
      payload.forEach((row, index) => {
        if (row.chat_from_role === 'CS') {
          row.chat_from_reference_uid = 0
        }
        if (row.text_message !== '' || row.attachment_media_type !== null) {
          setLoading(true)
          if (row.attachment_media_type !== null) {
            row.attachment_url = attachmentList[index]?.file
            createWithFile(row, handleResponseCreateMessage, setLoading)
          } else {
            row.additional = dataRoom.additional
            createWithoutFile(row, handleResponseCreateMessage, setLoading)
          }
        }
      })
      // RESET
      let objDataRoom = { ...dataRoom }
      objDataRoom.additional = ''
      setDataRoom(objDataRoom)
      handleCancelAttachment()
    }
  }

  function handleDragAndDropEvent(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  function handleDragAndDropFiles(event) {
    handleDragAndDropEvent(event)
    if (!event.dataTransfer) return
    handleUploadFile({
      target: {
        files: event.dataTransfer.files,
      },
    })
  }

  return (
    <>
      {(() => {
        if (dataRoom.additional?.product) {
          return <CardProduct dataRoom={dataRoom} onRemoveAdditional={handleRemoveAdditional} />
        } else if (dataRoom.additional?.quotation) {
          return <CardQuotation dataRoom={dataRoom} onRemoveAdditional={handleRemoveAdditional} />
        } else if (dataRoom.additional?.transaction) {
          return <CardTransaction dataRoom={dataRoom} onRemoveAdditional={handleRemoveAdditional} />
        } else if (attachmentList.length > 0) {
          return (
            <CardAttachment
              attachment={attachmentList}
              removeAttachment={handleRemoveAttachment}
              payloadActive={payloadActive}
              cancelAttachment={handleCancelAttachment}
              onChangeKeyword={handleMessageInputActive}
              onUpload={handleUploadFile}
            />
          )
        }
      })()}
      <div
        className="chat-footer border-top"
        onDrop={handleDragAndDropFiles}
        onDragEnter={handleDragAndDropEvent}
        onDragLeave={handleDragAndDropEvent}
        onDragOver={handleDragAndDropEvent}
      >
        <div className="attachment">
          <div className="dropdown">
            <button className="btn btn-sm" type="button" onClick={() => fileRef.current.click()}>
              <i className="fa fa-paperclip" />
            </button>
            <input
              className="d-none"
              ref={fileRef}
              type="file"
              accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf,video/mp4,video/x-m4v,video/*,image/*"
              multiple
              onChange={handleUploadFile}
            />
          </div>
        </div>
        <textarea
          className="form-control emojionearea-form-control border-0"
          id="messageInput"
          rows={1}
          placeholder={t('placeholder.type_message')}
          value={payload[payloadActive]?.text_message}
          style={{ width: 'calc(100% - 150px)', resize: 'none' }}
          onChange={handleMessageInput}
          onDrop={handleDragAndDropFiles}
          onDragEnter={handleDragAndDropEvent}
          onDragLeave={handleDragAndDropEvent}
          onDragOver={handleDragAndDropEvent}
        />
        {!loading ? (
          <>
            <Link
              to={'#'}
              onClick={() => onEmojiShow()}
              className={`btn-icon emoji-icon text-dark mt-1 ${
                payload[payloadActive]?.text_message ? 'with-chat' : 'no-chat'
              }`}
            >
              <i className="fa fa-smile font-size-3 text-primary" />
            </Link>
            {showEmoji && (
              <div className="emoji-picker">
                <Picker disableSearchBar={true} onEmojiClick={onEmojiClick} />
              </div>
            )}
            {payload[payloadActive]?.text_message && (
              <Link
                to={'#'}
                className="btn btn-primary send-icon text-dark mt-1"
                onClick={() => handleSendMessage()}
              >
                {t('button.send')} <i className="fa fa-paper-plane ml-1"></i>
              </Link>
            )}
          </>
        ) : (
          <div className="btn btn-primary send-icon text-light mt-1">
            {t('title.sending')}
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default MessageFooter
