import React from 'react'
import PropTypes from 'prop-types'
import { redirectLink, currencyFormat } from '../../utility/Helpers'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const BubbleItemAdditional = ({ item, type }) => {
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)

  return (
    <>
      {item?.product && (
        <a
          href={redirectLink({
            account: account,
            product: item.product,
          })}
        >
          <div
            className={
              type && type === 'self'
                ? 'container message-additional'
                : 'container message-additional-over'
            }
          >
            <div className="row">
              <div className="col-2">
                <img src={item.product.product_media_url} className="image-overview" alt="" />
              </div>
              <div className="col-10 d-inline-block">
                <div className="col-12 d-inline-block">
                  <div className="d-flex justify-content-between">
                    <div className="row col-12">{item.product.product_title}</div>
                  </div>
                </div>
                <div className="col-12 font-weight-bold">
                  {currencyFormat(item.product.product_price, item.product.product_price_currency)}
                </div>
              </div>
            </div>
          </div>
        </a>
      )}
      {item?.quotation && (
        <a
          href={redirectLink({
            account: account,
            quotation: item.quotation,
          })}
        >
          <div
            className={
              type && type === 'self'
                ? 'container message-additional'
                : 'container message-additional-over'
            }
          >
            <div className="row">
              <div className="col-12 d-inline-block">
                <div className="col-12 d-inline-block">
                  <div className="d-flex justify-content-between">
                    <div className="row col-1">
                      <i className="fa fa-file fa-3x" />
                    </div>
                    <div className="row col-11 font-weight-bold">
                      <div className="row col-12 p-0 m-0">
                        {t(`initial_status.${item.quotation.status}`)} <br />
                        {t(`title.rfq_number`) + ': ' + item.quotation.request_code}
                        <br />
                        {currencyFormat(
                          item.quotation.payment_amount,
                          item.quotation.payment_amount_currency,
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      )}
      {item?.transaction && (
        <a
          href={redirectLink({
            account: account,
            transaction: item.transaction,
          })}
        >
          <div
            className={
              type && type === 'self'
                ? 'container message-additional'
                : 'container message-additional-over'
            }
          >
            <div className="row">
              <div className="col-12 d-inline-block">
                <div className="col-12 d-inline-block">
                  <div className="d-flex justify-content-between">
                    <div className="row col-1">
                      <i className="fa fa-shopping-cart fa-3x" />
                    </div>
                    <div className="col-10 font-weight-bold">
                      <div className="row col-12 p-0 m-0">
                        {t(`initial_status.${item.transaction.status}`)} <br />
                        {t(`title.order_number`) + ': ' + item.transaction.transaction_code}
                        <br />
                        {currencyFormat(
                          item.transaction.payment_amount,
                          item.transaction.payment_amount_currency,
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      )}
    </>
  )
}

BubbleItemAdditional.propTypes = {
  item: PropTypes.any,
  type: PropTypes.any,
}

export default BubbleItemAdditional
