import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Room, Message } from '../../components/Main'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import logo from '../../assets/image/logo/logo.png'
import { FetchActiveRoom } from '../../redux/room/actionCreator'

const Home = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { account } = useSelector((state) => state.account)
  const location = useLocation()
  const [openChat, setOpenChat] = useState(false)
  const [payloadConversation, setPayloadConversation] = useState({})

  const handleChatOpen = (row) => {
    setPayloadConversation(row)
    setOpenChat(true)
  }

  const handleChatClose = () => setOpenChat(false)

  useEffect(() => {
    if (location.state) {
      dispatch(FetchActiveRoom(location.state.room_code))
      handleChatOpen(location.state)
    }
  }, [dispatch, location])

  return (
    <>
      <div className="no-scroll-wrapper">
        <div className="main-layout">
          <aside className="sidebar">
            <div className="tab-chat-list sidebar-theme">
              <div className="tab-room active" id="chats-content">
                <div className="d-flex flex-column custom-height">
                  <div className="hide-scrollbar h-100" id="chatContactsList">
                    <Room onOpen={handleChatOpen} />
                  </div>
                </div>
              </div>
            </div>
          </aside>
          {!openChat ? (
            <div className="main">
              <div className="chats">
                <div className="d-flex flex-column justify-content-center text-center h-100 w-100">
                  <div className="container">
                    <img className="avatar-img" src={logo} alt="" />
                    <h5>
                      {t('title.welcome')}, {account.full_name}
                    </h5>
                    <p className="text-muted">{t('title.start_chat')}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Message payloadConversation={payloadConversation} onClose={handleChatClose} />
          )}
          <div className="backdrop" />
        </div>
      </div>
    </>
  )
}

export default Home
