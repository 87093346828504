import { useCallback } from 'react'
import ApiService from '../../../../services/ApiService'
import { useDispatch, useSelector } from 'react-redux'
import { FetchListRooms, SetLoadingMessage } from '../../../../redux/room/actionCreator'
import { getRoomMap } from '../../../../utility/Message'

const InteractorChatMessageCreate = () => {
  const { account } = useSelector((state) => state.account)
  const dispatch = useDispatch()
  const { listRooms, activeRoom } = useSelector((state) => state.room)
  const objRoom = useCallback(
    (data, payload) => {
      const dataMap = { ...data }
      dataMap.chat_to_name = payload.chat_to_name
      dataMap.chat_to_picture = payload.chat_to_picture
      dataMap.chat_to_role = payload.chat_to_role
      dataMap.chat_to_uid = payload.chat_to_reference_uid
      const newObj = getRoomMap(account, dataMap, listRooms, activeRoom)
      let prevList = listRooms.filter((room) => room.room_code !== data.room_code)
      prevList.unshift(newObj)
      dispatch(FetchListRooms(prevList))
      dispatch(SetLoadingMessage(true))
    },
    [account, activeRoom, dispatch, listRooms],
  )
  const createWithoutFile = useCallback(
    (payload, callback, loading) => {
      ApiService.jsonRequest('/communication-chat/message-create', payload, (response) => {
        if (response.status_code === 200) {
          if (response.data) {
            const data = response.data
            data.additional = payload.additional
            objRoom(data, payload)
            callback(data)
          } else {
            callback(null)
          }
        }
        loading(false)
      })
    },
    [objRoom],
  )

  const createWithFile = useCallback(
    (payload, callback, loading) => {
      const payloadFile = {
        type: 'PUBLIC',
        category: 'CHAT-MESSAGE',
        file: payload.attachment_url,
        reference_uid: payload.room_code,
        updated_by: account.full_name,
      }
      ApiService.formRequest('/storage-file/upload', payloadFile, (responseFile) => {
        if (responseFile.status_code === 200) {
          const url_file = responseFile.data[0].url
          const payloadMessage = { ...payload }
          payloadMessage.attachment_url = url_file
          ApiService.jsonRequest(
            '/communication-chat/message-create',
            payloadMessage,
            (response) => {
              if (response.status_code === 200 && response.data) {
                objRoom(response.data, payload)
                callback(response.data)
              } else {
                callback(null)
              }
              loading(false)
            },
          )
        } else {
          callback(null)
          loading(false)
        }
      })
    },
    [account.full_name, objRoom],
  )
  return { createWithoutFile, createWithFile }
}

export default InteractorChatMessageCreate
