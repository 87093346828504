import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const BubbleItemAttachment = ({ item }) => {
  return (
    <>
      {item.attachment_media_type && (
        <div className="container message-attachment">
          <div className="row">
            {['JPG', 'JPEG', 'PNG', 'GIF', 'BMP'].includes(
              item.attachment_media_type.toUpperCase(),
            ) ? (
              <Zoom>
                <img alt={item.text_message ?? ''} src={item.attachment_url} width="250" />
              </Zoom>
            ) : (
              <Link
                to={'#'}
                onClick={() => {
                  window.location.href = item.attachment_url
                }}
              >
                <i className="fa fa-file text-dark fa-5x" />
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  )
}

BubbleItemAttachment.propTypes = {
  item: PropTypes.any,
}

export default BubbleItemAttachment
