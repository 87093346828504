import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useShowMenuButton from '../../hooks/useShowMenuButton'
import pictureDefault from '../../assets/image/profile/profile.png'

const MessageHeader = (data) => {
  const { t } = useTranslation()
  const [dataRoom, setDataRoom] = useState({})
  const menuRef = useRef(null)
  const [openMenu, setOpenMenu] = useShowMenuButton(menuRef)
  const handleMenuButton = () => setOpenMenu(!openMenu)
  const handleChatClose = () => data.onClose()

  useEffect(() => {
    setDataRoom(data.dataRoom)
  }, [data.dataRoom])

  return (
    <>
      <div className="chat-header">
        <Link className="text-muted d-xl-none mr-2" to={'#'} onClick={handleChatClose}>
          <svg className="hw-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            ></path>
          </svg>
        </Link>
        <div className="media chat-name align-items-center text-truncate">
          <div className="avatar d-none d-sm-inline-block mr-3">
            <img
              src={
                dataRoom.chat_to_picture || dataRoom.chat_to_picture !== ''
                  ? dataRoom.chat_to_picture
                  : pictureDefault
              }
              alt=""
            />
          </div>
          <div className="media-body align-self-center">
            <h6 className="text-truncate mb-0">{dataRoom.chat_to_name}</h6>
          </div>
        </div>
        <ul className="nav flex-nowrap">
          <li className="nav-item list-inline-item d-none d-sm-block mr-0" ref={menuRef}>
            <div className="dropdown">
              <Link
                className="nav-link text-muted px-1"
                to={() => false}
                role="button"
                title="Detail"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={handleMenuButton}
              >
                <svg className="hw-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                  ></path>
                </svg>
              </Link>
              <div className={`dropdown-menu dropdown-menu-right ${openMenu && 'show'}`}>
                {dataRoom.chat_to_role === 'SELLER' && (
                  <Link
                    className="dropdown-item align-items-center d-flex"
                    to={'#'}
                    onClick={() => {
                      window.location.href =
                        process.env.REACT_APP_DOMAIN_MARKETPLACE +
                        '/store/detail/' +
                        dataRoom.chat_to_uid
                    }}
                  >
                    <i className="fa fa-store mr-1"></i> {t('title.store_visit')}
                  </Link>
                )}{' '}
                <Link
                  className="dropdown-item align-items-center d-flex"
                  to={'#'}
                  onClick={handleChatClose}
                >
                  <i className="fa fa-times mr-1"></i> {t('title.close')}
                </Link>
              </div>
            </div>
          </li>
          <li className="nav-item list-inline-item d-sm-none mr-0">
            <div className="dropdown">
              <Link
                className="nav-link text-muted px-1"
                to={() => false}
                role="button"
                title="Details"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <svg className="hw-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                  ></path>
                </svg>
              </Link>
              <div className="dropdown-menu dropdown-menu-right">
                <Link
                  className="dropdown-item align-items-center d-flex"
                  to={() => false}
                  data-toggle="collapse"
                  data-target="#searchCollapse"
                  aria-expanded="false"
                >
                  <span>
                    <i className="fa fa-times mr-1"></i> {t('title.close')}
                  </span>
                </Link>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}

export default MessageHeader
