import { FETCH_ACCOUNT, SET_ERROR_ACCOUNT } from './actions'
import { getCookies } from '../../utility/Cookies'
import { decryptCrypto } from '../../utility/Encryption'

const initialState = {
  account: getCookies('_wchat') ? decryptCrypto(getCookies('_wchat')) : null,
  error_account: true,
}

export default function AccountReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT:
      return {
        ...state,
        account: action.data,
      }
    case SET_ERROR_ACCOUNT:
      return {
        ...state,
        error_account: action.data,
      }
    default:
      return state
  }
}
