import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { currencyFormat } from '../../utility/Helpers'

const CardProduct = (data) => {
  const [dataCard, setDataCard] = useState(null)
  useEffect(() => {
    if (data.dataRoom?.additional !== '') {
      setDataCard(data.dataRoom?.additional.product)
    }
  }, [data.dataRoom?.additional])

  const handleRemoveAdditional = () => {
    setDataCard(null)
    data.onRemoveAdditional('PRODUCT')
  }

  return (
    <>
      {dataCard && (
        <div className="card-item">
          <div className="card-item-content">
            <div className="container">
              <div className="row pt-2 pb-2">
                <div className="col-2">
                  <img src={dataCard.product_media_url} className="image-overview" alt="" />
                </div>
                <div className="col-10 d-inline-block">
                  <div className="col-12 d-inline-block">
                    <div className="d-flex justify-content-between">
                      <div className="row col-11">{dataCard.product_title}</div>
                      <div className="row col-1 mt-1">
                        <Link to={'#'} onClick={() => handleRemoveAdditional()}>
                          <i className="fa fa-times" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 font-weight-bold">
                    {currencyFormat(dataCard.product_price, dataCard.product_price_currency)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CardProduct
