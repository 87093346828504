import React, { useState, useEffect, useCallback } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { requestForToken, onMessageListener } from './FirebaseInitialize'
import { useDispatch, useSelector } from 'react-redux'
import { InteractorAccountToken } from '../interactors/Main'
import { FetchListRooms, SetLoadingMessage, SetNewMessage } from '../redux/room/actionCreator'
import { getRoomMap } from '../utility/Message'

const FirebaseNotification = () => {
  const dispatch = useDispatch()
  const { account } = useSelector((state) => state.account)
  const { listRooms, activeRoom } = useSelector((state) => state.room)
  const { syncToken } = InteractorAccountToken()
  const [notification, setNotification] = useState({ title: '', body: '' })
  const notify = () => toast(<ToastDisplay />)
  function ToastDisplay() {
    return (
      <div>
        <div className="font-weight-bold">
          <i className="fa fa-info-circle text-red" /> {notification?.title}
        </div>
        <div className="mt-1">{notification?.body}</div>
      </div>
    )
  }

  const getToken = useCallback(async () => {
    const browser_token = await requestForToken()
    if (browser_token && browser_token !== account?.notification_token) {
      syncToken(account, browser_token)
    }
  }, [account, syncToken])

  useEffect(() => {
    getToken()

    if (notification?.title) {
      notify()
    }
  }, [getToken, notification?.title])

  if (onMessageListener) {
    onMessageListener()
      .then((payload) => {
        const notification = payload?.notification
        const data = payload?.data
        if (data.notification_target === 'CHAT') {
          const dataMap = { ...data }
          dataMap.chat_to_name = data.chat_from_name
          dataMap.chat_to_picture = data.chat_from_picture
          dataMap.chat_to_role = data.chat_from_role
          dataMap.chat_to_uid = data.chat_from_uid
          dataMap.additional = JSON.parse(dataMap.additional)
          const newObj = getRoomMap(account, dataMap, listRooms, activeRoom)
          let prevList = listRooms.filter((room) => room.room_code !== data.room_code)
          prevList.unshift(newObj)
          dispatch(FetchListRooms(prevList))
          dispatch(SetLoadingMessage(true))
          if (activeRoom === data.room_code) {
            const dataMessage = { ...data, additional: JSON.parse(data.additional) }
            dispatch(SetNewMessage(dataMessage))
          } else {
            setNotification({
              title: notification.title,
              body: notification.body,
            })
          }
        }
      })
      .catch(() => {})
  }

  return (
    <Toaster
      position="top-right"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        className: '',
        duration: 3000,
      }}
    />
  )
}

export default FirebaseNotification
