import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import noImage from '../../assets/image/profile/profile.png'
import { convertDate } from '../../utility/Time'
import { BubbleItemAdditional, BubbleItemAttachment } from './../Main'

const BubbleItem = ({ item }) => {
  const { account } = useSelector((state) => state.account)
  let message_type = ''
  let message_avatar = item.chat_from_picture === '' ? noImage : item.chat_from_picture
  if (item.chat_from_role === 'CS' && account.role === 'CS') {
    message_type = 'self'
    message_avatar = account.profile_picture === '' ? noImage : account.profile_picture
  } else if (item.chat_from_uid === account.reference_uid) {
    message_type = 'self'
    message_avatar = account.profile_picture === '' ? noImage : account.profile_picture
  }

  return (
    <>
      <div className={`message ${message_type}`}>
        {item.chat_from_role === 'CS' && message_type === 'self' && (
          <div className="message-wrapper">
            <div className="message-cs-name">
              <strong>{item.created_by}</strong>
            </div>
          </div>
        )}
        <div className="message-wrapper">
          <div className="message-content">
            {item.additional !== '' && (
              <BubbleItemAdditional item={item.additional} type={message_type} />
            )}
            {item.attachment_media_type !== '' && <BubbleItemAttachment item={item} />}
            <span>{item.text_message}</span>
          </div>
        </div>
        <div className="message-options">
          <div className="avatar avatar-sm bg-light">
            <img alt="" src={message_avatar} />
          </div>
          <span className="message-date">{convertDate(item.created_date, 'long')}</span>
        </div>
      </div>
    </>
  )
}

BubbleItem.propTypes = {
  item: PropTypes.any,
}

export default BubbleItem
