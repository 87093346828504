import {
  FETCH_ACTIVE_ROOM,
  FETCH_LIST_ROOMS,
  SET_NEW_MESSAGE,
  SET_LOADING_MESSAGE,
} from './actions'

const initialState = {
  activeRoom: null,
  listRooms: [],
  newMessage: null,
  loadingMessage: false,
}

export default function RoomReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACTIVE_ROOM:
      return {
        ...state,
        activeRoom: action.data,
      }
    case FETCH_LIST_ROOMS:
      return {
        ...state,
        listRooms: action.data,
      }
    case SET_NEW_MESSAGE:
      return {
        ...state,
        newMessage: action.data,
      }
    case SET_LOADING_MESSAGE:
      return {
        ...state,
        loadingMessage: action.data,
      }
    default:
      return state
  }
}
