import React from 'react'
const Loading = () => {
  return (
    <>
      <div style={{ padding: '24px' }}>
        <div className="loading"></div>
        <div className="text-center">Loading...</div>
      </div>
    </>
  )
}

export default Loading
