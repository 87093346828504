import { useCallback } from 'react'
import ApiService from '../../../../services/ApiService'

const InteractorChatMessageSearch = () => {
  const messageSearch = useCallback((payload, callback, loading) => {
    ApiService.jsonRequest('/communication-chat/message-search', payload, (response) => {
      if (response.status_code === 200 && response.data) {
        callback(response.data)
      } else {
        callback(null)
      }
      loading(false)
    })
  }, [])
  return { messageSearch }
}

export default InteractorChatMessageSearch
