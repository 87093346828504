import React from 'react'
import { PageNotFound, Conversation, Home } from './../containers/Main'

import i18n from './../utility/i18n'
function getTitle(key) {
  let lang = i18n.t(key)
  if (lang === undefined) {
    lang = 'Retail Ecommerce, Built For You'
  }
  return lang
}
const RoutePath = [
  {
    path: '/',
    title: getTitle('menu.home'),
    element: <Home />,
    auth: false,
  },
  {
    path: '/conversation/start',
    title: getTitle('menu.conversation'),
    element: <Conversation />,
    auth: false,
  },
  { path: '*', title: '404', element: <PageNotFound />, auth: false },
]

export default RoutePath
