import { useCallback } from 'react'
import ApiService from '../../../../services/ApiService'

const InteractorChatRoomConversation = () => {
  const roomConversation = useCallback((payload, callback) => {
    ApiService.jsonRequest('/communication-chat/room-conversation', payload, (response) => {
      if (response.status_code === 200) {
        if (response.data) {
          callback(response.data)
        }
      }
    })
  }, [])
  return { roomConversation }
}

export default InteractorChatRoomConversation
