import React from 'react'
import { AppContent } from './Main'

const AppLayout = () => {
  return (
    <>
      <AppContent />
    </>
  )
}

export default AppLayout
