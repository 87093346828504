import { findByArray } from './Helpers'

export const getRoomMap = (account, data, listRooms, activeRoom) => {
  let chat_target = ''
  if (
    (account.role === 'CUSTOMER' && data.chat_from_role === 'SELLER') ||
    (account.role === 'SELLER' && data.chat_from_role === 'CUSTOMER')
  ) {
    chat_target = 'SOC'
  } else if (
    (account.role === 'CUSTOMER' && data.chat_from_role === 'CS') ||
    (account.role === 'CS' && data.chat_from_role === 'CUSTOMER')
  ) {
    chat_target = 'COCS'
  } else if (
    (account.role === 'SELLER' && data.chat_from_role === 'CS') ||
    (account.role === 'CS' && data.chat_from_role === 'SELLER')
  ) {
    chat_target = 'SOCS'
  }
  const dataObj = findByArray(listRooms, 'room_code', data.room_code)
  let totalUnread = 0
  if (activeRoom !== data.room_code) {
    if (dataObj) {
      totalUnread = dataObj.total_unread + 1
    } else {
      totalUnread = 1
    }
  }
  const map = {
    additional: data.additional,
    chat_target: chat_target,
    chat_to_name: data.chat_to_name,
    chat_to_picture: data.chat_to_picture,
    chat_to_role: data.chat_to_role,
    chat_to_uid: data.chat_to_uid,
    last_message_attachment_media_type: data.attachment_media_type ?? '',
    last_message_datetime: data.created_date,
    last_message_text: data.text_message,
    room_code: data.room_code,
    total_unread: totalUnread,
  }
  return map
}
