import { combineReducers } from 'redux'
import AccountReducer from './account/reducers'
import LoadingReducer from './loading/reducers'
import RoomReducer from './room/reducers'

const rootReducers = combineReducers({
  loading: LoadingReducer,
  account: AccountReducer,
  room: RoomReducer,
})

export default rootReducers
