import { useCallback } from 'react'
import ApiService from '../../../../services/ApiService'

const InteractorChatRoomSearch = () => {
  const roomSearch = useCallback((payload, callback, loading) => {
    ApiService.jsonRequest('/communication-chat/room-search', payload, (response) => {
      if (response.status_code === 200) {
        if (response.data) {
          callback(response.data)
        }
      }
      loading(false)
    })
  }, [])
  return { roomSearch }
}

export default InteractorChatRoomSearch
