import { FETCH_ACCOUNT, SET_ERROR_ACCOUNT } from './actions'

export const FetchAccount = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_ACCOUNT,
        data: data,
      })
    } catch (err) {
      dispatch({
        type: SET_ERROR_ACCOUNT,
        data: err.response,
      })
    }
  }
}
