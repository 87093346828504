import {
  FETCH_ACTIVE_ROOM,
  FETCH_LIST_ROOMS,
  SET_NEW_MESSAGE,
  SET_LOADING_MESSAGE,
} from './actions'

export const FetchActiveRoom = (data) => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_ACTIVE_ROOM,
      data: data,
    })
  }
}

export const FetchListRooms = (data) => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_LIST_ROOMS,
      data: data,
    })
  }
}

export const SetNewMessage = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_NEW_MESSAGE,
      data: data,
    })
  }
}

export const SetLoadingMessage = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_LOADING_MESSAGE,
      data: data,
    })
  }
}
