import { useCallback, useEffect } from 'react'
import { Buffer } from 'buffer'
import { useNavigate } from 'react-router-dom'
import { InteractorChatRoomConversation } from '../../interactors/Main'

const Conversation = () => {
  const navigate = useNavigate()
  const { roomConversation } = InteractorChatRoomConversation()
  const handleResponse = useCallback(
    (row) => {
      if (row) {
        navigate('/', {
          state: row,
        })
      } else {
        navigate('/')
      }
    },
    [navigate],
  )

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const jsonPayload = Buffer.from(queryParams.get('uid'), 'base64').toString()
    try {
      const json = JSON.parse(jsonPayload)
      const payload = {
        chat_target: json.chat_target ?? null,
        chat_from_uid: json.chat_from_uid ?? null,
        chat_from_role: json.chat_from_role ?? null,
        store_uid: json.store_uid ?? null,
        customer_account_uid: json.customer_account_uid ?? null,
        team_account_uid: json.team_account_uid ?? null,
        product_uid: json.product_uid ?? null,
        quotation_uid: json.quotation_uid ?? null,
        transaction_uid: json.transaction_uid ?? null,
      }
      roomConversation(payload, handleResponse)
    } catch (e) {
      navigate('/')
    }
  }, [handleResponse, navigate, roomConversation])

  return null
}

export default Conversation
