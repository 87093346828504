import React from 'react'
import { useTranslation } from 'react-i18next'
import { Breadcrumb } from '../../components/Main'
const PageNotFound = () => {
  const { t } = useTranslation()
  return (
    <>
      <Breadcrumb title={t('title.page_not_found')} />
      <div className="text-center pb-3 border-bottom border-color-1">
        <h1 className="font-size-sl-72 font-weight-light mb-3">404!</h1>
        <p className="text-gray-90 font-size-20 mb-0 font-weight-light">
          {t('message.page_not_found')}
        </p>
      </div>
    </>
  )
}

export default PageNotFound
