import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import pictureDefault from '../../assets/image/profile/profile.png'
import logo from '../../assets/image/logo/logo.png'
import { convertDate } from '../../utility/Time'
import { InteractorChatRoomSearch } from '../../interactors/Main'
import { useDispatch, useSelector } from 'react-redux'
import { FetchActiveRoom, FetchListRooms, SetLoadingMessage } from '../../redux/room/actionCreator'

const ChatRoom = (data) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { account } = useSelector((state) => state.account)
  const { listRooms, loadingMessage } = useSelector((state) => state.room)
  const [loading, setLoading] = useState(true)
  const { roomSearch } = InteractorChatRoomSearch()
  const [payload, setPayload] = useState({
    page: 1,
    limit: 10,
    keywords: {
      text_search: '',
      store_uid: account.role === 'SELLER' ? account.reference_uid : null,
      customer_account_uid: account.role === 'CUSTOMER' ? account.reference_uid : null,
      team_account_uid: account.role === 'CS' ? account.reference_uid : null,
    },
  })

  const handleTextSearch = (e) => {
    e.preventDefault()
    const value = e.currentTarget.value
    const obj = { ...payload }
    obj.keywords.text_search = value
    setPayload(obj)
  }

  const handleTextSearchClear = (e) => {
    e.preventDefault()
    const obj = { ...payload }
    obj.keywords.text_search = ''
    setPayload(obj)
    setLoading(true)
    roomSearch(obj, handleResponse, setLoading)
  }

  const handleSearch = (e) => {
    e.preventDefault()
    roomSearch(payload, handleResponse, setLoading)
  }

  const handleResponse = useCallback(
    (row) => {
      let data = []
      if (row.items?.length > 0) {
        data = row.items
      }
      dispatch(FetchListRooms(data))
    },
    [dispatch],
  )

  useEffect(() => {
    if (loading) {
      roomSearch(payload, handleResponse, setLoading)
    } else {
      if (loadingMessage) {
        dispatch(SetLoadingMessage(false))
      }
    }
  }, [dispatch, handleResponse, listRooms, loading, loadingMessage, payload, roomSearch])

  const handleOpenChat = (row) => {
    dispatch(FetchActiveRoom(row.room_code))
    data.onOpen(row)
  }

  return (
    <>
      {/* SEARCH */}
      <div className="sidebar-header sticky-top">
        <div className="row">
          <div className="col">
            <Link
              to={'#'}
              onClick={() => {
                let url = process.env.REACT_APP_DOMAIN_MARKETPLACE
                if (account.role === 'CS') {
                  url = process.env.REACT_APP_DOMAIN_CMS
                } else if (account.role === 'SELLER') {
                  url = process.env.REACT_APP_DOMAIN_SELLER
                }
                window.location.href = url
              }}
            >
              <img alt="" src={logo} style={{ maxWidth: '100px' }} />
            </Link>
            <div className="mt-3 badge bg-red text-white">{t(`role.${account.role}`)}</div>
          </div>
        </div>
        <div className="sidebar-sub-header">
          <div className="input-group">
            <input
              type="text"
              className="form-control py-2 pl-5 font-size-15 border-3 height-40 rounded-left-pill border-right-0 border-primary"
              placeholder={t('placeholder.input_keyword')}
              value={payload.keywords.text_search}
              onChange={handleTextSearch}
            />
            <div className="input-group-append">
              <div
                className={`border border-primary border-left-0 btn text-primary py-1 font-weight-bold font-size-22 rounded-0 text-center ${
                  payload.keywords.text_search === '' ? 'd-none' : 'd-block'
                }`}
                type="button"
                onClick={handleTextSearchClear}
              >
                <span aria-hidden="true">&times;</span>
              </div>
              <button
                className="btn btn-primary height-40 py-2 px-3 rounded-right-pill"
                type="submit"
                onClick={handleSearch}
              >
                <span>{t('button.search')}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* END SEARCH */}

      {/* ROOM LIST */}
      <ul className="room-list">
        {!loadingMessage &&
          listRooms?.map((room, index) => {
            return (
              <li key={index} className="room-item friends" id={`room-code-${room.room_code}`}>
                <Link className="room-link" to={'#'} onClick={() => handleOpenChat(room)}>
                  <div className="avatar">
                    <img
                      src={room.chat_to_picture !== '' ? room.chat_to_picture : pictureDefault}
                      alt=""
                    />
                  </div>
                  <div className="room-content">
                    <div className="room-info">
                      <h6 className="chat-name text-truncate">{room.chat_to_name}</h6>
                      <div className="chat-time">{convertDate(room.last_message_datetime)}</div>
                    </div>
                    <div className="room-text">
                      <p className="text-truncate">
                        {room.last_message_text !== '' ? (
                          room.last_message_text
                        ) : ['JPG', 'JPEG', 'PNG', 'GIF', 'TIFF', 'BMP'].includes(
                            room.last_message_attachment_media_type.toUpperCase(),
                          ) ? (
                          <>
                            <i className="fa fa-image" /> {t('title.image')}
                          </>
                        ) : (
                          <>
                            <i className="fa fa-file" /> {t('title.file')}
                          </>
                        )}
                      </p>
                      {room.total_unread > 0 && (
                        <div
                          className="badge badge-rounded badge-warning font-weight-bold p-2 ml-1"
                          id={`badge-${room.room_code}`}
                        >
                          {room.total_unread > 99 ? '99+' : room.total_unread}
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              </li>
            )
          })}
      </ul>
      {/* END ROOM LIST */}
    </>
  )
}

export default ChatRoom
