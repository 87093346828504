import moment from 'moment'
import { currency } from '../constant/global/currency'

export const inputNumber = (value) => {
  const pattern = /^[0-9\b]+$/
  if (value !== '' && pattern.test(value)) {
    return value
  }
  return ''
}

export const prettyUrl = (value) => {
  value = value.replace(/[^a-zA-Z ]/g, '')
  return value
    .replace(/ /g, '-')
    .replace(/@/g, '')
    .replace(/\$/g, '')
    .replace(/!/g, '')
    .replace(/#/g, '')
    .toLowerCase()
}

export const categoryUrl = (name, main, major, minor) => {
  const main_uid = main !== null ? '/' + main.uid : ''
  const major_uid = major !== null ? '.' + major.uid : ''
  const minor_uid = minor !== null ? '.' + minor.uid : ''
  const slug = prettyUrl(name) + main_uid + major_uid + minor_uid
  return `/product/category/${slug}`
}

export const numberFormatDestroy = (value) => {
  const parsed = typeof value === 'string' || value instanceof String ? value : value.toString()

  return parsed.replace(/([^0-9,]+)/g, '').replace(',', '.')
}

export const numberFormat = (value) => {
  let number_string = value.toString().replace(/[^,\d]/g, ''),
    split = number_string.split(','),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi)

  if (ribuan) {
    let separator = sisa ? '.' : ''

    rupiah += separator + ribuan.join('.')
  }

  rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah

  return rupiah
}

export const currencyFormat = (value, currency_type) => {
  return currency[currency_type] ? `${currency[currency_type]} ${numberFormat(value)}` : value
}

export const subStringContent = (str, total) => {
  if (str.length > total - 1) return str.slice(0, total) + '...'
  return str
}

export const toDataUrl = (url, callback) => {
  let xhr = new XMLHttpRequest() //XMLHttpRequest (XHR)
  xhr.onload = function () {
    let reader = new FileReader()
    reader.onloadend = function () {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

export const findByArray = (array, key, value) => {
  return array.find((element) => {
    return element[key] === value
  })
}

export const formatDate = (value, format) => {
  return moment(new Date(value)).format(format)
}

export const capitalizeFirstWord = (str) => {
  const words = str.toLowerCase().split(' ')
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1)
  }
  return words.join(' ')
}

function removeArray(array) {
  var what,
    a = arguments,
    L = a.length,
    ax
  while (L > 1 && array.length) {
    what = a[--L]
    while ((ax = array.indexOf(what)) !== -1) {
      array.splice(ax, 1)
    }
  }
  return array
}

export const removeArrayString = (array, value) => {
  return removeArray(array, value)
}

export const splitString = (str, maxLength) => {
  let words = str.split(' ')
  for (let j = 0; j < words.length; j++) {
    let k = words[j].length
    if (k > maxLength) {
      let temp = []
      for (let i = 0; i < k; i++) {
        temp.push(words[j].substr(i, maxLength))
        i += maxLength
      }
      words[j] = temp.join(' ')
    }
  }
  return words.join(' ')
}

export const GroupByCreatedDate = (array) => {
  const grouped = array.reduce((m, { devider: label, ...rest }) => {
    const group = m.get(label) || { label, options: [] }
    group.options.push({ ...rest })
    return m.set(label, group)
  }, new Map())
  return [...grouped.values()]
}

export const removeLastWord = (str, minusRange) => {
  return str.split(' ').slice(0, minusRange).join(' ')
}

export const getExtension = (filename) => {
  return filename.split('.').pop()
}

export const redirectLink = (obj) => {
  let url = process.env.REACT_APP_DOMAIN_MARKETPLACE
  if (obj.account.role === 'CS') {
    url = process.env.REACT_APP_DOMAIN_CMS
  } else if (obj.account.role === 'SELLER') {
    url = process.env.REACT_APP_DOMAIN_SELLER
  }
  // PRODUCT
  if (obj?.product) {
    const item = obj.product
    if (obj.account.role === 'CS') {
      url += `/product/view?uid=${item.uid}`
    } else if (obj.account.role === 'SELLER') {
      url += `/product`
    } else {
      url += `/product/detail/${prettyUrl(item.product_title) + '/' + item.uid}`
    }
  }
  // RFQ
  if (obj?.quotation) {
    const item = obj.quotation
    if (obj.account.role === 'CS') {
      url += `/rfq/manage?ref=${item.request_code}`
    } else if (obj.account.role === 'SELLER') {
      url += `/rfq?ref=${item.request_code}`
    } else {
      url += `/quotation/request?ref=${item.request_code}`
    }
  }
  // TRANSACTION
  if (obj?.transaction) {
    const item = obj.transaction
    if (obj.account.role === 'CS') {
      url += `/transaction/manage`
    } else if (obj.account.role === 'SELLER') {
      url += `/order?ref=${item.transaction_code}`
    } else {
      url += `/order?ref=${item.transaction_code}`
    }
  }
  return url
}
