import { formatDate } from './Helpers'

export const convertDate = (dateParam, type = 'short') => {
  if (!dateParam) {
    return null
  }
  const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam)
  const today = new Date()
  const isToday = today.toDateString() === date.toDateString()

  if (isToday) {
    return formatDate(date, 'HH:mm A')
  } else {
    if (type === 'short') {
      return formatDate(date, 'DD/MM/YYYY')
    } else {
      return formatDate(date, 'DD/MM/YYYY HH:mm:ss A')
    }
  }
}
