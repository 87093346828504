import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

const CardAttachment = (data) => {
  const fileRef = useRef(null)
  const [dataAttachment, setDataAttachment] = useState([])
  const [formAttachment, setFormAttachment] = useState([])

  const mime_type = [
    'image/bmp',
    'image/gif',
    'image/ief',
    'image/jpeg',
    'image/svg+xml',
    'image/tiff',
    'image/png',
  ]

  const generateForm = (attachment) => {
    let arrForm = []
    for (let index = 0; index < 5 - attachment; index++) {
      const obj = {
        index: index,
      }
      arrForm.push(obj)
    }
    setFormAttachment(arrForm)
  }
  useEffect(() => {
    if (data.attachment?.length) {
      setDataAttachment(data.attachment)
      if (data.attachment.length !== 5) {
        generateForm(data.attachment.length)
      }
    }
  }, [data.attachment])

  const handleCancelAttachment = () => data.cancelAttachment()

  const onChangeKeyword = (val) => data.onChangeKeyword(val)

  const handleRemoveAttachment = (val) => data.removeAttachment(val)

  return (
    <>
      <div className="container-fluid card-attachment">
        <div className="row">
          <div className="col-md-12">
            <div className="row justify-content-end mt-3 mr-1">
              <Link to={'#'} onClick={() => handleCancelAttachment()}>
                <i className="fa fa-times fa-2x" />
              </Link>
            </div>
            <div className="row p-3 mb-3 text-center">
              <div className="col-md-12">
                {mime_type.includes(dataAttachment[data.payloadActive]?.file_type) ? (
                  <img
                    alt=""
                    className="img-250"
                    src={URL.createObjectURL(dataAttachment[data.payloadActive].file)}
                  />
                ) : (
                  <i className="fa fa-file text-primary fa-10x" />
                )}
              </div>
            </div>
            <div className="row text-center mb-3 justify-content-center">
              {dataAttachment.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      className="col col-lg-2 mb-2 pointer"
                      onClick={() => onChangeKeyword(index)}
                    >
                      <div className="row">
                        <div className="col-6 mt-2">
                          {mime_type.includes(data.file_type) ? (
                            <img alt="" className="img-100" src={URL.createObjectURL(data.file)} />
                          ) : (
                            <div className="attachment-item-file justify-content-center">
                              <i className="fa fa-file text-dark fa-4x" />
                            </div>
                          )}
                        </div>
                        <div className="col-6">
                          <div
                            onClick={() => handleRemoveAttachment(index)}
                            className="attachment-icon-delete"
                          >
                            <i className="fa fa-times text-dark" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )
              })}
              {formAttachment.map((row) => {
                return (
                  <React.Fragment key={row.index}>
                    <div className="col col-lg-2 mt-2">
                      <div
                        onClick={() => fileRef.current.click()}
                        className="attachment-item-file justify-content-center"
                      >
                        <i className="fa fa-plus fa-4x" />
                      </div>
                    </div>
                  </React.Fragment>
                )
              })}
            </div>
            {/* FILE */}
            <input
              ref={fileRef}
              type="file"
              style={{ display: 'none' }}
              accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf,video/mp4,video/x-m4v,video/*,image/*"
              multiple
              onChange={(e) => data.onUpload(e)}
            />
            {/* END FILE */}
          </div>
        </div>
      </div>
    </>
  )
}

export default CardAttachment
